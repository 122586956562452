/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * Detailed information about this CSS: h5bp.com/css
 *
 * ==|== normalize ==========================================================
 */
/* =============================================================================
   HTML5 display definitions
   ========================================================================== */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

/* =============================================================================
   Base
   ========================================================================== */
/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: helvetica, arial,sans-serif;
  color: #636363; }

body {
  margin: 0 auto 0 auto;
  line-height: 1.4;
  background-color: #d3dee3; }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */
::-moz-selection {
  background: #989898;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #989898;
  color: #fff;
  text-shadow: none; }

/* =============================================================================
   Links
   ========================================================================== */
a {
  color: #b20838; }

a:visited {
  color: #b20838; }

a:hover {
  color: #3c3b3b; }

a:active {
  color: #b20838; }

a:focus {
  outline: thin dotted; }

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover, a:active {
  outline: 0; }

/* =============================================================================
   Lists
   ========================================================================== */
ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* =============================================================================
   Embedded content
   ========================================================================== */
/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/i/440
 */
img, a img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

img, object, embed {
  max-width: 100%;
  height: auto; }

/*
 * Correct overflow not hidden in IE9
 */
svg:not(:root) {
  overflow: hidden; }

/* =============================================================================
   Chrome Frame Prompt
   ========================================================================== */
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

/*IE7*/
html.lt-ie9.lt-ie8 #hdrExtra {
  margin-top: -28px;
  float: right;
  clear: both; }

/*----CONTENT --------------------*/
#container {
  width: 990px;
  margin: 0px auto 0px auto;
  position: relative;
  border: 0px solid blue; }

#TopGroup {
  width: 100%;
  height: 129px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #ffffff; }

#MiddleGroup {
  width: 815px;
  height: 393px;
  background: url(/wp-content/uploads/2020/09/Background_Web.jpg) no-repeat 0 0;
  background-color: #006ca9;
  padding-top: 105px;
  padding-left: 175px; }

.HeaderText {
  color: #ffffff;
  font-size: 28pt;
  font-weight: normal;
  padding: 0px;
  margin: 0px; }

.ContentText {
  width: 55%;
  color: #ffffff;
  font-size: 14pt;
  font-weight: normal;
  padding: 0px;
  margin: 0px; }

#BottomGroup {
  width: 96%;
  font-family: Arial, Helvetica;
  font-weight: bold;
  font-size: 10px;
  color: #006ca9;
  padding: 10px;
  text-align: right; }

/* ==|== media queries ======================================================
   EXAMPLE Media Query for Responsive Design.
   This example overrides the primary ('mobile first') styles
   Modify as content requires.
   ========================================================================== */
/* Style adjustments for viewports that meet the condition */
/*SMALL PHONE*/
/* TABLET (portrait and landscape) ----------- */
/*TABLET LANDSCAPE*/
/*TABLET PORTRAIT*/
/*PHONE*/
@media only screen and (max-width: 767px) {
  /*RESET CONTAINERS TO PERCENTAGE WIDTH ----------------*/
  #container {
    width: 100% !important;
    margin: 0 auto 3px auto; }
  #TopGroup {
    width: 100%;
    height: 75px;
    background: url(../images/TopLogo_Mobile.jpg) no-repeat 0 0;
    background-color: #ffffff; }
  #MiddleGroup {
    width: 100%;
    height: 250px;
    background: url(../images/Background_Web.jpg) no-repeat 50% 50%;
    background-color: #006ca9;
    padding: 25px 0px 0px 0px;
    margin: 0px; }
  .HeaderText {
    color: #ffffff;
    font-size: 145%;
    font-weight: normal;
    padding: 10px 0px 0px 25px;
    margin: 0px; }
  .ContentText {
    width: 80%;
    color: #ffffff;
    font-size: 80%;
    font-weight: normal;
    padding: 0px 10px 0px 25px;
    margin: 0px;
    border: 0px red solid; }
  #BottomGroup {
    width: 92%;
    font-family: Arial, Helvetica;
    font-weight: bold;
    font-size: 10px;
    color: #006ca9;
    padding: 10px;
    text-align: right; } }

/* ==|== print styles =======================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  /* Black prints faster: h5bp.com/s */
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  /* Don't show links for images, or javascript/internal links */
  .ir {
    text-indent: 0 !important; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  nav, header {
    display: none !important; }
  .hidePrint {
    display: none !important; }
  h1 {
    height: auto !important; } }
